body {
  background-color:#1b1b1a;
  font-family: Roboto;
  color:#fff;
  margin:0;
  box-shadow: none !important; 
}
.cosmetics-inner{
  margin-left: 9.4%;
  .carousel .slider-wrapper {
    margin-top: 55px;
  }
  a {
    float: left;
  }
  .cosmetics-see-details {
    margin-top:12px;
    margin-bottom:62px;
    div {
      cursor: pointer;
      color: rgba(250, 250, 250, .87) !important;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.72px;
    }
  }
  .interested-questions {
    margin-right:4%;
    height: 160px;
  }
  .header-part {
    .interested-questions {
      margin-right:4%;
      height: auto !important;
    }
  }
  ul {
    width: 20% !important;
    li {
      margin-right: 40px !important;
      img {
        border-radius: 4px;
      }
    }
  }
  .four-image-content {
    margin-bottom:100px;
    white-space: pre-wrap;
    .interested-questions {
      text-align: left;
      overflow: hidden;
    }
  }
}
.cosmetics-inner-img {
  min-width: 267px;
  max-width: 267px;
  height:267px;
  float:left;
}
.active-tab {
  color:#fd9700;
  border: none;
  a{
    color:#fd9700 !important
  }
}
.footer-menu-block {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;

  .menu-title {
    color: rgb(253, 151, 0);
  }
  .menu-item {
    a{
      color: rgba(250,250,250, .87) !important;
      text-decoration: none !important;
      &:hover {
        color: rgba(250,250,250, .87) !important;
        cursor: pointer;
      }
    }
  }
}
.header{
  width:100%;
  height:50px;
  .container {
    margin-left: 9%;
    margin-right: 8%;
    margin-top: 65px;
    height: 100%;
    .logo-content {
      float:left;
      width:15%;
      height:100%;
      img {
        width: 100%;
        height: 95px;
        margin-top: -20px;
        object-fit: unset !important;
      }
    }
    .menu-items-content {
      float: left;
      width: 80%;
      text-align: right;
      .menu-item {
        display:inline-block;
        margin-left: 4%;
        height: 18px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.75px;
        line-height: 15.6px;
        text-transform: uppercase;
        &:last-child {
          margin-left: 50px;
        }
        .hover-icon {
          margin-top: -15px;
          &:before {
            margin-right:30%;
            border-top: 20px solid transparent;
            border-bottom: 20px solid #222221;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            content: '';
          }
        }
        &:hover {
          color:#fd9700;
          cursor: pointer !important;
        }

        a{
          color: #fff;
          text-decoration: none;
          &:hover {
            color:#fd9700;
            cursor: pointer;
          }
        }
        &:hover {
          cursor: default;
        }
        button {
          a {
            color: #000000 !important;
          }
        }
      }
    }
  }
}
.header-content {
  margin-top: 100px;
}
.mobile-drawer {
  div {
    background-color: #1b1b1b !important;
  }
  .cosmetics-see-details {
    margin-left: 11.5%;
    margin-top: 70px;
    div {
      color: #fd9700;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.38px;
      line-height: 15.6px;
      text-transform: uppercase;
      &:hover {
        cursor: pointer !important;
      }
    }
  }
  li {
    color:#fff;
  }
  .close-icon {
    img {
      width: 17px;
      height:17px;
      float: right;
      padding: 50px;
    }
  }
  .menu-item {
    padding: 30px 40px;
    .menu-names {
      padding-bottom:15px;
    }
    .submenu-items {
      line-height:30px;
      a {
        color: #fff !important;
        text-decoration: none !important;
      }
    }
  }
  .MuiDrawer-paperAnchorLeft-4, .MuiBackdrop-root-64, .MuiPaper-root-3,
  .jss106, .jss124, .jss5, .jss6 {
    right:0 !important;
    background-color:#1b1b1b !important;
  }
}
.subMenu-coll {
  // transition: height 400ms linear 0s;
  overflow: hidden;
  height:0;
}
.for-hover {
  width: 62%;
  height: 302px;
  float: left;
  position: absolute;
  top: 102px;
  right: 9.5%;
}
.submenu_collapse {
  width: 100%;
  height: 252px;
  float: left;
  background-color: #222221;
  margin-top: 16px;
  // transition: height 2400ms linear 2s;
  // -webkit-transition: opacity 2s;
  // transition: opacity 2s;
  // opacity: 0;
  .four-image-item{
    min-width: 147px;
    max-width: 130px;
    margin-right: 3%;
    height: 147px;
    float: left;
  }
  .submenu-content {
    width: 28%;
    padding: 55px 0 47px 61px;
    float: left;
    .submenu {
      padding: 0;
      margin:0;
      // opacity: 0.85;
      color: #ffffff;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1.6px;
      line-height: 20px;
    }
    li {
      list-style-type: none;
      padding-bottom:15px;
      a {
        text-decoration: none;
        color:rgba(250, 250, 250, .85);
        font-size: 14px;
        &:hover {
          color:#fff;
          text-decoration: none;
        }
      }
    }
  }
  .images-content {
    width: 50%;
    padding:54px 17px 58px 0;
    height: 100%;
    float: right;
    .images {
      width: 37%;
      max-width: 152px;
      height: 150px;
      float:left;
      margin-left: 24px;
      img {
        border-radius: 4px;
      }
    }
  }

}
img {
  width:100%;
  height: 100%;
}
.grid-list-item {
  padding: 10px;
  img {
    border-radius: 4px;
  }
  &:hover {
    .grid-images-text {
      button {
        opacity: 1;
      }
    }
  }
}
// .grid-images-text 
//   button {
//     transition: 1s;
//   }
.homepage-grid-images-button {
  transition: 1s;
}
.grid-list-item > div:first-child {
  display:flex;
  justify-content: center;
  align-items: center;
}
.grid-images-text{
  background-color: transparent !important;
  > .grid-images-text__desc {
    text-align:center;
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.32px;
    line-height: 36px;
  }
  button {
    //display: none !important;
    opacity: 0;
    background-color:#fff;
    margin-top: 49px;
    &:hover {
      background-color:#fff;
    }
  }
}
.solarium {
  width: 82%;
  margin-left: 9%;
  margin-right: 9%;
  float: left;
  height: auto;
  .title {
    color: #ffffff;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.32px;
    line-height: 29px;
    margin-bottom: 20px;
  }
  .content-menu-address {
    height: auto;
    width: 90%;
    float: left;
    margin-left: 5%;
    margin-top:28px;
    line-height:15px;
    font-size: 14px;
    opacity: 0.87;
    padding-top: 8px;
  }
  .content-menu-address.active-tab {
    border-top:2px solid #fd9700;
  }
  .context{
    //width:46%;
    float:left;
    height: auto;
    // opacity: 0.87;
    color: rgba(255, 255, 255, 0.87);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 19px;
    margin-bottom:60px;
  }
  .images-content {
    display: flex;
    justify-content: space-between;
    margin-top : 58px;
    margin-bottom : 34px;
    .solarium-images {
      width: 100%; //must be 520px
      max-height: 388px;
      min-height: 218px;
      max-width: 388px;
      min-width: 218px;
      float: left;
      border-radius: 4px;
      background-color: #2c3031;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .menu-content-position {
    margin-right: 4%; //must be 187px;
    margin-left: 4%; //must be 178px;
    float: left;
    margin-top: 20px;
    width: 92%;
    height:auto;
  }
  .content-menu {
    width: 90%;
    line-height: 21px;
    margin: 160px 5%;
    height: 49px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.54);
    float: left;
    display: flex;
    justify-content: space-around;
    .content-menu-items {
      min-width:250px;
      //max-width:250px;
      text-align:center;
      display: flex;
      float: left;
      flex-flow: column;
      &:hover, &:active {
        color: #ff9f00f5;
        cursor: pointer;
      }
    }
  }
  .bs-active-tab {
    height: 49px;
    border-bottom: 2px solid;
  }
  .text-with-images {
    float: left;
    width: 100%;
    margin-top:80px;
    margin-bottom: 120px;
    .menu-text-content{
      float:left;
      width:40%;
      .title {
        // opacity: 0.87;
        color: #ffffff;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1.33px;
      }
      .context {
        // opacity: 0.87;
        color: rgba(255, 255, 255, 0.87);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.18px;
        line-height: 19px;
        float: left;
        width: 100%;
        height: auto;
        margin-top:10px;
        ul {
          padding: 0;
          li {
            list-style-type: none;
          }
        }
      }

    }
  }
  .additional-info {
    width: 238px;
    height: auto;
    opacity: 0.87;
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    .time, .cost {
      display:flex;
      justify-content: space-between;
    }
  }
  .menu-image-content {
    float: right;
    width: 57%;
    height: auto;
    display:flex;
    justify-content: space-between;
    .menu-image-item {
      width: 50%;
      max-height: 299px;
      max-width: 299px;
      margin-right: 6.4%;
      border-radius: 4px;
      &:last-child{
        margin-right:0
      }
    }
  }
  .three-images-with-text {
    font-size: 14px;
    text-align: right;
  }
}
.beauty-salon-f-text {
  width: 57%;
  font-size: 14px;
  color: rgba(255, 255, 255, .87);
  font-weight: 400;
  letter-spacing: 0.81px;
}
.beauty-salon-without-hr {
  margin-top: 172px;
  float: left;
  height: auto;
  width: 100%;
}
.beauty-salon-hr {
  float: left;
  height: auto;
  width: 100%;
  margin-top: 158px;
  margin-bottom: 168px;
  hr{
    border-color: #fd9700;
  }
}




.tips-and-questions {
  margin-top:200px;
  float:left;
  width: 100%;
  &__title {
    width: 259px;
    height: auto;
    margin:24px auto;
    color: #ffffff;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 33.6px;
  }
  &__context {
    width: 700px;
    height: auto;
    margin:0 auto;
    opacity: 0.87;
    // color: rgba(255, 255, 255, 0.87) !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.33px;
    line-height: 19px;
    text-align: center;
    div {
      margin-top: 24px;
      font-weight: 700;
    }
  }
  .button-position {
    width:100%;
    display: flex;
    margin-top:64px;
  }
  &__button {
    margin: 0 auto 200px auto;
      button {
        width: 200px;
        height: 48px;
        border-radius: 4px;
        background-color: #fd9700 !important;
        color: #000000;
        font-family: Roboto;
        font-weight: 400;
        letter-spacing: 0.33px;
        line-height: 15.6px;
        text-transform: uppercase;
        span {
          font-size: 14px !important;
        }
      }
  }
}

.interested-questions {
  height: auto;
  width:100%;
  float:left;
  margin-right: 50px;
  display:inline-block;
  &__question {
    width: 100%;
    height: auto;
    // opacity: 0.87;
    color: #ffffff;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.2px;
    margin-top: 0;
  }
  &__answer {
    width: 100%;
    height: auto;
    font-size: 14px;
    color: rgba(255, 255, 255, .87);
    font-weight: 400;
    letter-spacing: 0.18px;
    line-height: 19px;
  }

}


.floatLeft {
  float:left !important;
}
.floatRight {
  float:right !important;
}
.title {
  float:left;
  width:100%;
  height:auto;
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.33px;
  margin: 0 0;

}
.Collapsible {
  background-color: #1b1b1a;
  span {
    width:100%;
    display: block;
    color: rgba(250, 250, 250, .87);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.37px;
  }
    .title {
      color: rgba(250, 250, 250, .87);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.43px;
    }
  .coll-text {
    color: rgba(250, 250, 250, .87);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.18px;
    line-height: 20px;
  }
  .show-less-more{
    color: rgba(250, 250, 250, .87);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-transform: uppercase;
  }
}
.header-content {
  .text {
    margin-bottom: 51px;
    line-height: 19px !important;
  }
}
.text {
  color: rgba(250, 250, 250, .87);
  font-size: 14px !important;
  font-weight: 400!important;
  letter-spacing: 0.72px !important;
  line-height: 21px !important;
}
  .tips-and-questions__title {
    //margin: 0 !important;
    text-align: left !important;
  }
.collapse-grid {
  float: left;
  height: auto;
  margin-bottom: 39px !important;
  flex-grow: 1;
}
.about-texts {
  height:auto;
  width:100%;
  float:left;
  margin-bottom: 200px;
  &__items {
    width: 100%;
    height: auto;
    // opacity: 0.87;
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 22px;
    float:left;
    &:last-child {
      float:right;
    }
  }
}
.about-texts .about-texts__items {
  padding-top: 1%;
}

.text .ql-align-justify {
  padding-top: 1%;
}
.show-less-more {
  width:60%;
  float:right;
  height:auto;
  margin-top:30px;
  margin-bottom:30px;
  hr {
    border-color:#fd9700;
    float:left;
    width:39%;
  }
  div {
    float:left;
    width:50%;
    margin-left: 9%;
    &:hover {
      cursor: pointer;
    }
  }
}
.cosmetics-carousel {
  float: left;
  width: 100%;
  margin-right: 20%;
}
.cosmetics-inner {
  .header-part {
    width: 40%;
  }
  .four-image-content {
    .carousel.carousel-slider .control-arrow:hover {
      background-color: unset;
    }
    .control-prev.control-arrow {
      right: 4% !important;
      left: unset !important;
      width: 40px !important;
    }
    .control-next.control-arrow {
      width: 40px !important;
      margin-top: 23px;
    }
  }
}
.cosmetics-page-position {
  margin-top: 100px;
}
.copyright {
  margin-top: 101px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.3px;
  line-height: 15.6px;
  opacity: 0.54;
  color: rgba(253, 151, 0, .54);
  .home-hr {
    margin-right: 1.5% !important;
    width:40px !important;
  }
  .last-text {
    margin-left: 3%;
  }
}
.footer-mobile {
  float: left;
  width: 88%;
  margin-left: 7%;
  height: auto;
  margin-top: 200px;
  text-align: center;
  .footer-text {
    color: rgba(255, 255, 255, .87);
  }
    .footer-last-part {
      text-align:left;
      h3 {
        color:#fd9700;
      }
    }
  .contacts-icons {
    width:100%;
    float:left;
    margin-bottom: 30px;
    hr {
      width: 25%;
      color: #fd9700 !important;
    }

    .icon-width {
      width: 20px;
      height:20px;
      margin-left:5%;
      margin-right:5%;

      img {
        object-fit: contain !important;
      }
    }

  }
}
.header-mobile-social {
  margin-left: 9.5%;
  margin-top: 51px;
  margin-bottom: 64px;
  .contacts-icons {
    width:100%;
    float:left;
    .icon-width {
      width: 20px;
      height:20px;
      margin-left:3%;
      margin-right:3%;

      img {
        object-fit: contain !important;
      }
    }
}}
.four-image-content {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 37px;
  white-space: nowrap;
  justify-content: space-between;

  .four-image-item {
    min-height: 100px;
    width: 30%;
    max-width: 281px;
    max-height: 281px;
    margin-right: 40px;
    img {
      border-radius: 4px
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.four-title-and-description {
  width:100%; //110% ?????
  float: left;
  display: flex;
  justify-content: space-between;
}

.video-and-text-content {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height:auto;
  float:left;
  .video-content {
    width: 49%;
    height: 260px;
    float: left;
    border-radius: 4px;
    .menu-image-content {
      .menu-image-item {
        width:100% !important;
      }
    }
  }
  .text-content {
    width: 40%;
    float:right;

  }
  .button-position {
    height: auto;
    float: left;
    width: 100%;
    margin-top: 45px; //91px
    display: flex;
  }
}

.coast-table {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 72px;
  hr {
    width: 30px;
    float: left;
    margin-top: 14px;
    border-color: #fd9700;
  }
  .header {
    width:100%;
    height:auto;
    float:left;
    opacity: 0.87;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.72px;
    margin-bottom: 24px;
    .row {
      div {
        color: #fd9700;
        width: 50%;
      }
      &:first-child {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  .row {
    width: 100%;
    // opacity: 0.87;
    color: rgba(255, 255, 255, .87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    line-height: 28px;
    float:left;
  }
  .body {
    width:100%;
    height:auto;
    float:left;
  }
}
.tanning, .about {
  .about-texts__items {
    color: rgba(255, 255, 255, .87);
    letter-spacing: 0.4px;
    line-height: 20px;
    font-size: 14px !important;
  }
  .title {
    color: rgba(255, 255, 256, 1);
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.2px;
  }
}
.tanning {
  .button-position {
    margin-top: 59px !important;
    margin-bottom: 175px;
  }
}
.tanning-coast-table {
  margin-top:40px;
  margin-bottom:30px;
}

.text-content {
  // opacity: 0.87;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.72px;
  .about-info {
    margin-top: 30px;
  }
}
.about {
  .title {
    margin-bottom: 24px;
    font-size:32px;
    font-weight: bold;
  }
  .about-grid-texts {
    min-height: 100px;
    width: 30%;
    max-width: 281px;
    max-height: 281px;
    margin-right: 40px;
  }
  .four-title-and-description-distance{
    margin-bottom: 200px;
    a {
      min-height: 100px;
      width: 37%;
      max-width: 281px;
      max-height: 281px;
    }
  }
  .interested-questions {
    min-height: 100px !important;
    width: 37% !important;
    max-width: 281px !important;
    max-height: 281px !important;
    margin:0 !important;
  }
}
.cosmetics {
  .interested-questions {
    width: 19%;
    float: unset;
  }
  .interested-questions__answer {
    white-space: pre-wrap;
    text-align: left;
  }
}
.cosmetics-page-position {
  margin-bottom: 300px;
}


.cosmetics-page {
  .four-image-content{
    margin-top:40px;
    float: left;
    height: auto;
    ul {
      width: 20%;
      li {
        &:first-child{
          margin-right: 0;
        }
        margin-right: 40px;
      }
    }
    a {
      width: 100%;
      display: inline-block;
      margin-right: 13%;
    }
  }
}

.carousel {
  .slide {
    background-color: #1b1b1a!important;
    img {
      margin-right: 20%;
      max-height: 275px;
      max-width: 275px;
    }
  }
}
.gift-card {
  .small-title {
    .interested-questions {
      width: unset !important;
      margin-right: 0%;
    }
    .interested-questions__question {
      max-width: 328px;
      font-size:14px;
      margin-bottom:37px;
      color:#fff;
      letter-spacing: 0.37px;
    }
    .interested-questions__answer {
      max-width: 328px;
      letter-spacing: 0.37px;
      color: rgba(255, 255, 255, .87);
    }
    p {
      line-height: 10px;
    }
  }
  .images-content {
    margin-bottom: 87px;
  }
}
.gift-card-context {
  margin-top: 200px;
  margin-bottom: 200px;
}
.center-text {
  width:100%;
  float:left;
  height:auto;
  margin-top: 106px;
  .row{
    width:70%;
    margin-left:15%;
    margin-right:15%;
    opacity: 0.87;
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.81px;
    text-align: center;
  }
}

.center-and-top {
  margin-top: 40px;
  justify-content: center !important;
}

.block-div{
  overflow: auto;
}

.map {
  float:left;
  width: 82%;
  height:65vh;
  margin:0 9%;
  border-radius: 4px !important;
  border: 3px solid #212121;

}
.text {
  font-size: 16px;
  color: rgba(255, 255, 255, .87 );
  letter-spacing: 0.4px;
  line-height: 20px;
}

.home .text-with-images{
  margin-bottom: 60px !important;
}

.home {
  .title-and-description-position {
    width:60%;
    height:auto;
    float:left;
    margin-top:100px;
    margin-bottom:49px;
  }
  .center-title-and-description {
    margin-left:27%;
    height:auto;
    float:left;
    margin-right:27%;
    text-align:center;
    margin-top: 200px;
    .interested-questions__question {
      margin-bottom: 20px;
    }
    .buttons-position {
      margin-top: 57px;
      // width: 80%;
      // margin-left: 32%;
      // margin-right: 32%;
      height: auto;
      // float: left;
      // display: flex;
      >a {
        // margin-right: 40px;
      }
    }
  }
  .text-with-images {
    width:100%;
    height:auto;
    margin-bottom: 0px;
    &__text {
      margin-top:30px;
      width:24%;
      float:left;
      height:auto;
      button {
        margin-top:40px;
      }
    }
    .interested-questions {
      margin-bottom: 40px;
    }
    .images-content {
      width: 74%;
      float:right;
      .four-image-content .four-image-item{
        width: 31%;
        max-width: 285px;
        max-height: 285px;
        margin-right: 31px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
.footer-page {
  float: left;
  &.cosmetics-inner {
    margin-left: 0!important;
    ul {
      width:21% !important;
      li {
        margin-right: 0 !important;
      }
    }
    .carousel {
      .control-prev.control-arrow {
        margin-top: -16px;
      }
      .selected, .selected ~ .slide ~ .slide ~.slide ~.slide ~ .slide  {
        opacity: 0.5;
        .last-li-element {

        }
      }

      .slide {
        &:first-child {
          margin-left: -57% !important;
        }
        img {
          max-height: 292px;
          max-width: 292px;
          width:90% !important;
        }
      }
    }
    .carousel .slider-wrapper {
      margin-top:30px !important;
    }
  }
  .four-image-content {
    margin-bottom: 191px !important;
    .control-next.control-arrow {
      margin-top:7px !important;
    }
  }
}
.footer {
  float: left;
  height: auto;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  .footer_location {
    padding-bottom: 65px;
    border-bottom: 1px solid rgba(253, 151, 0, .54);
    color: rgb(253, 151, 0); //.87
    height: auto;
    float: left;
    width: 100%;
    .location_max_width {
      min-width: 72%;
      max-width: 72%;

      float:left;
      height:auto;
      padding-left: 30px;
    }
    .location-block {
        float: left;
        margin-right: 10%;
        margin-bottom: 5%;
        font-size: 14px;
        width: 26%;
        white-space: pre;
        //max-width: 210px;
      &:last-child {
        margin-right: 0;
      }
      .location {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .button-div {
      height: 100%;
      display: flex;
      align-items: center;
      float: right;
      margin-top: 4%;
      padding-right: 30px;
      button {
        width: 160px;
      }
    }

  }
  .footer-last-part {
    float: left;
    height: auto;
    width: 70%;
    margin-top: 78px;
    .footer-menu-block {
      float: left;
      height: auto;
      margin-right: 5%;
      &:first-child {
        padding-left: 30px;
      }
    }
  }
  .footer-contact-part {
    height: auto;
    float:right;
    width: 27%;
    margin-top: 78px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-right: 30px;
    .footer-logo-img-size {
      // max-width: 189px !important;
      // height: 51px !important;
      // margin-bottom: 10px;
      width: 46%;
      height: 100%;
      margin-bottom: 10px;
    }
    img {
      max-width: 245px;
      max-height: 118px;
    }
    .contacts-icons {
      width:100%;
      display: flex;
      justify-content: flex-end;
      div {
        float:left;
        margin-right:21px;
        a{
          display: block;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .icons-text {
        line-height: 28px;
        font-size:14px;
        color: rgba(250, 250, 250, .54);
        font-weight: 400;
        letter-spacing: 1.3px;
      }
      .icons {
        font-size: 24px;
      }
      .icons-image {
        height: 18px;
        width: 18px;
      }
    }
  }

  .footer_menu_name {
    color: #fd9700;
    font-size: 14px;
    text-transform: uppercase;
  }

  .footer_submenus {
    color:#fff;
    font-size: 14px;
    text-transform: capitalize;
  }
}

.contact-table {
  width: 80%;
  float:left;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 132px;
  img {
    width: auto;
    height: auto;
  }
  table {
    background-color:#1b1b1b;
    th,td {
      text-align: left !important;
    }
  }
  thead{
    th {
      color: #ffffff;
      border-color: rgba(253, 151, 0, .34);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.37px;
    }
  }
  tbody {
    .table-row {
      height:100px;
      white-space: pre-wrap;       
      th, td {
        background-color: #1b1b1a !important;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.37px;
        border-color: rgba(253, 151, 0, .34);
      }
      th {
        color: rgba(253, 151, 0, .75);
        letter-spacing: 0.4px;
      }
      td {
        color: rgba(250, 250, 250, .75);
        letter-spacing: 0.4px;
        line-height: 21px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
        &:last-child {
          td, th {
            border: none;
            background-color: #1b1b1b;
          }
        }
    }
  }

}
.contact-iframe {
  display: flex;
  justify-content: center;
  margin: 150px 10% 300px 13%;
  width: 74%;
  height: 500px;
  float: left;
  iframe {
    width: 100%;
    height:100%;
    float: left;
  }
}

button {
  width: 150px;
  height: 40px;
  background-color: #fd9700;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  a{
    text-decoration: none;
    color: #101010;
  }
  &:hover {
    background-color: #FFAA2C;
    color: rgba(10, 10, 10, .80);
    cursor: pointer;
  }
  &:active, &:focus {
    outline: none;
    border:none;
  }
}
.details-button {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.87);
  color:#fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.65px;
  &:hover {
    background-color: #fff;
    color: rgba(10,10,10, .87);
    cursor: pointer;
  }
}
.table {
  background-color: #101010 !important;
}
.display-none{
  display: none !important;
}
.display-block{
  display: block !important;
}
.position {
  height: auto;
  float: left;
  width: 100%;
}
.home-hr {
  width: 10%;
  float: left;
  margin-right: 4%;
  border-color:#fd9700;
}
.home-page-image-grid {
  width: 100%;
  li {
    .position {
      margin-bottom: 20px;
    }
  }
}
.home-hr-title {
  color: #fd9700;
  float: left;
}

.cosmetics-inner-header{
  margin-bottom: 188px;
  margin-top: 170px;
  float: left;
  .header-part {
    width: 55%;
    margin-left: 9.7%;
    float: left;
    // margin-top: 30px;
  }
}

.button-black {
  color: #0a0a0a !important; 
}

@media only screen and (max-width: 1280px) {
  .container {
    margin-right: 5% !important;
  }
}
@media only screen and (min-width: 1024px) {
  .second-grid-img {
    height:46% !important;
  }
}
@media only screen and (min-width: 1140px) {
  .second-grid-img {
    height:46% !important;
  }
  .about .interested-questions, .about .four-title-and-description-distance a {
    max-width: 221px !important;
  }
}
@media only screen and (min-width: 1215px) {
  .about .interested-questions, .about .four-title-and-description-distance a {
    max-width: 221px !important;
  }
}
@media only screen and (min-width: 1250px) {
  .second-grid-img {
    height:46.3% !important;
  }
  .about .interested-questions, .about .four-title-and-description-distance a {
    max-width: 252px !important;
  }
}
@media only screen and (min-width: 1440px) {
  .second-grid-img {
    height:47% !important;
  }
  .submenu_collapse .images-content {
    width: 42% !important;
  }
  .for-hover {
    width: 67% !important;
  }
  .about .interested-questions, .about .four-title-and-description-distance a {
    max-width: 265px !important;
  }
}
@media only screen and (min-width: 1366px) {
  .collapse{
    .submenu_collapse{
      top: 110px !important
    }
  } 
}
@media only screen and (min-width: 1442px) {
  .second-grid-img {
    height:45.1% !important;
  }
  .about .interested-questions, .about .four-title-and-description-distance a {
    max-width: 281px !important;
  }
}
@media only screen and (min-width: 1600px) {
  .about .interested-questions, .about .four-title-and-description-distance a {
    max-width: 281px !important;
  }

    .cosmetics-inner-header .header-part {
      width: 44% !important;
    }

  .submenu_collapse .images-content {
    width: 58% !important;
    .images {
      width: 39%;
    }
  }
  .footer-page {
    &.cosmetics-inner {
      .carousel {
        .slide {
          img {
            max-height: 545px !important;
            max-width: 545px !important;
            margin-right: 40px;
            // width: 317px !important;
            // height: 317px !important;
            line-height: 20px;
          }
        }
      }
    }
  }
  .footer .footer_location .location {

  }
  .home .text-with-images .images-content .four-image-content .four-image-item {
      max-height: 540px !important;
      max-width: 540px !important;

    img{
      // max-height: 540px !important;
      // max-width: 540px !important;
      width: 317px !important;
      height: 317px !important;
    }
  } 

  .grid-list-item {
    padding: 20px;
  }
  .solarium .images-content .solarium-images {
    max-height: 740px !important;
    max-width: 740px !important;
  }
  .solarium .menu-image-content .menu-image-item {
    max-height: 570px !important;
    max-width: 570px !important;
  }
  .carousel .slide img {
    max-height: 480px !important;
    max-width: 480px !important;
  }
  .cosmetics-inner-img {
    max-width: 470px !important;
    height: 470px !important;
  }
  .for-hover {
    width: 40% !important;
    right: 21.8% !important;
  }
}

@media only screen and (max-width: 900px) {
  .solarium {
    margin-left: 40px !important;
    margin-right: 0px !important;
  }
  .show-less-more {
    width: 73% !important;
  }
  .map {
    // height: 100vh !important;
    border-radius: 4px !important;
    .carousel .slide {
      min-width: 89% !important;
    }
  }
  .cosmetics-page-position {
    margin-bottom: 30px;
    margin-top: 170px;
    .four-image-content {
      margin-top: 68px !important;
    }
  }
  .cosmetics-inner{
    margin-left: 9.4%;
    .header-part{
      margin-left: 0;
    }
    ul {
      width: 100% !important;
    }
    .carousel {
      .slide {
        min-width: 93.5% !important;
      }
    }
    .interested-questions {
      min-width: 93.5% !important;
      margin-left: 2% !important;
      text-align: left !important;
      overflow: hidden;
    }
    .four-image-content {
      white-space: pre-wrap !important;
    }
  }
  .footer-img-logo {
    width: 137px !important;
    height: 38px !important;
    margin-top: 111px;
    margin-bottom: 44px;
  }
  .footer-mobile {
    .footer-last-part {
      margin-top: 59px;
    }
    .copyright-mobile {
      margin-top:68px;
    }
    .home-hr-title{
      color: rgba(253, 151, 0, .54);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.3px;
      line-height: 15.6px;
    }
    .last-text {
      width: 100%;
      margin-top: 14px;
      margin-bottom: 35px;
      text-align:center;
    }
  }
  .Collapsible {
    span {
        margin-bottom: 40px;
      &.is-closed {
        background-image: url('../images/collapse_arrow.png') !important;
      }
        background-position: right;
        background-size: 13px 9px !important;
        background-repeat: no-repeat !important;
      &.is-open {
        background-image: url('../images/rotate-icon_orange.png') !important;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .contact-table {
    width: 90%;
    margin-right: 0;
  }
  .tips-and-questions {
    .tips-and-questions__button {
      margin-bottom: 170px !important;
    }
    margin-top: 97px !important;
  }
  .collapse-grid {
    margin-left: 4% !important;
  }
  .cosmetics-inner {
    .cosmetics-inner-header {
      margin-bottom: 30px;
    }
  }
  .carousel .slider-wrapper {
    margin-top: 0 !important;
    background-color:#1b1b1a;
  }
  .cosmetics-page {
    .four-image-content {
      ul {
        width: 100% !important;
        margin-top: 60px;
      }
    }
  }

  .solarium-title-desc.four-title-and-description,
  .gift-card .four-title-and-description {
    display: block !important;
    .menu-item {
      padding-bottom: 1px;
    }
  }
    .submenu-items {
      margin-left: 11.5%;
      color: #ffffff;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.75px;
      line-height: 32px;
      .menu-names {
        margin-top: 38px;
      }
      .submenu-items {
        font-weight: 400;
        margin-left: 0;
      }
      a {
        color: #fff !important;
        text-decoration: none !important;
        display: block;
      }
    }
  .home {
    .text-with-images {
      .images-content {
        margin-bottom:0 !important;
        margin-left: -40px !important;
        width: auto !important;
        margin-right: -20px !important;
      }
      margin-top: 191px !important;
      margin-bottom: 62px !important;
    }
    .title-and-description-position {
      width: auto !important;
    }
    .home-page-image-grid{
      width: auto !important;
      margin: 0;
    }
    .center-title-and-description{
      text-align: left !important;
    }
  }
  .four-image-item {
    width:100% !important;
    max-width:100% !important;
  }
  .row {
    width: 100% !important;
    float: left;
  }
  .cosmetics-page {
    .header-part {
    width: 100% !important;
    }
    .interested-questions__answer{
      white-space: pre-wrap !important;
      ul {
        width:100%;
        margin: 0;
      }
    }
  }
  .tips-and-questions__context {
    width:auto !important;
    text-align: left !important;
  }
  button {
    width: 200px !important;
    height: 48px !important;
  }
  .cosmetics-page-position {
    .carousel {
      .interested-questions {
        margin-top: 40px !important;
        text-align: left !important;
        margin-left: 5% !important;
      }
    }
  }
  .tanning {
    .collapse-grid {
      margin: 0 !important;
    }
    .tanning-title {
      margin-bottom: 49px !important;
    }
    .button-div {
      margin-top: 122px;
      margin-bottom:106px;
      display: flex;
      justify-content: center;
    }
    .video-and-text-content {
      width:auto !important;
      margin-left:2% !important;
      margin-right:2% !important;
    }

  }
  .header{
    width: auto !important;
    padding-right:15px;
    .logo-content {
      margin-top:41px;
      margin-left:30px;
      float:left !important;
      width: 120px;
    }
    .mobile-header-button {
      float:right !important;
      font-size: 33px;
      color: orange;
      margin-top: 33px;
      margin-right: 15px;
    }
  }
  .map {
    height: 70vh !important;
    width: 98% !important;
    margin: 60px 0 0 0 !important;
    border-radius: 4px !important;
    //border: 3px solid #dfd2ae; //tikon inchia border drel ?
    .carousel {
      .slide {
        width:295px !important;
        height:258px !important;
        background-color: #1b1b1a !important;
      }
      .interested-questions {
        white-space: pre-wrap;
        width: 100%;
      }
    }
    .four-image-content{
      margin-left: -38% !important;
      color:#fd9700 !important;
      .four-image-item {
        width: 100%;
        height: 100%;
        padding: 20px 27px !important;
        text-align:left !important;
      }
    }
  }
  .home {
    .buttons-position {
      width: 100% !important;
      justify-content: center !important;
      margin-left: 0 !important;
    }
    .center-title-and-description {
      margin: 0 !important;
    }
    .mobile-home {
      .four-image-item {
        margin-top: 30px;
      }
    }
  }
  .text-with-images {
    text-align: left;
    .images-content {
      width: 100% !important;
    }
    .text-with-images__text {
      width: 100% !important;
    }
  }
  .carousel {
    .slide {
      margin-right: 20px !important;
      min-width: 82% !important;
      img {
        float: left;
      }
    }
  }

}


.footer-images-content {
  .carousel {
    .slide {
      width: 21% !important;
      min-width: 21% !important;
      max-width: 21% !important;
      margin-right: 3% !important;
    }
    .slider-wrapper {
      margin-top:60px !important;
    }
  }

}
.marker-wrapper {
  position: absolute;
  top: 50%;
  left: 60%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
    cursor: pointer;
  }
}
.text-with-images__text {
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
}
.beauty-salon-page {
  .content-menu {
    margin-bottom : 110px;
    margin-top : 179px;
  }
  .button-position {
    button {
      margin-bottom: 0;
    }
  }
}

.interested-questions__answer{
  ul {
    width:100% !important;
    margin: 0 !important;
  }
  li, p, span, div {
    color: rgba(255, 255, 255, .87) !important;
  }
}

img {
  object-fit: cover;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.carousel .control-arrow {
  background-image: url('../images/arrow.png') !important;
  background-size: 17px 17px !important;
  background-repeat: no-repeat !important;
  &:before {
    border: none !important;
  }
  &.control-prev {
    transform: rotate(180deg) !important;
  }
}

.solarium-title-desc.four-title-and-description{
  padding-bottom:70px;
}
.homepage-grid-images-button { 
  margin: 0 auto;
}
.homepage-content-booknow {
  margin-left: 40px;
}
.homepage-contnet-see-deatils {
  margin-left: -20px;
}
.footer-image-svg { 
  height: 50%;
  margin-bottom: 15px;
}
.footer-right {
  margin-left: 0.8%;
}
.footer-left {
  margin-right: 0.8%;
}
.flex-end {
  justify-content: flex-end;
}
.book-now-modal {
  width: 900px;
  height: 380px;
}
.modal-close {
  width: 79px;
  height: 100%;
  float: right;
}
.visable {
  // -webkit-transition: opacity 2600ms, visibility 2600ms;
  // // transition: opacity 2600ms, visibility 2600ms;
  // visibility: hidden;
  // -webkit-transition: opacity 2s; /* For Safari 3.1 to 6.0 */
  // transition: opacity 2s;
}
.non-visable {
  // visibility: visible;
  // opacity: 1;
}
.view {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  cursor: default; }
  .view .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-attachment: fixed; border-radius: 4px;}
  .view img, .view video {
    position: relative;
    display: block; border-radius: 4px;}
  .view video.video-intro {
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto; border-radius: 4px;}

.overlay .mask {
  opacity: 0;
  transition: all 0.2s ease-in-out; border-radius: 4px;}
  .overlay .mask:hover {
    opacity: 1; border-radius: 4px;}

.zoom img, .zoom video {
  transition: all 0.2s linear; border-radius: 4px;}

.zoom:hover img, .zoom:hover video {
  transform: scale(1.025); 
  border-radius: 4px;
}

.video-and-text-content, .text-with-images, .four-title-and-description {
  .hidden_questions {
    .text, .interested-questions__answer, .context {
      height: 170px !important;
      overflow: hidden;
      float: left;
      color:rgba(250, 250, 250, .87);
      margin-bottom: 10px;
    }
    .coast-table {
      .body {
        .text {
          height: auto !important;
        }
      }
    }
  }
  .see-details {
    margin-bottom: 20px;
    font-size: 14px;
    &:hover {
      cursor: pointer !important;
    }
  }
}

.bold {
  font-weight: 700;
}

.tanning {
  .header-content {
    .text {
      font-size: 14px !important;
    }
  }
}
iframe {
  border: none !important;
}
.homepage-three-products {
  margin-top: 230px !important;
}
.top10{
  margin-top: 10px;
}
.cosmetics-see-details .home-hr {
  margin-top: 10px !important;
}
.laguna-text-color {
  color: #fd9700;
}
.laguna-grey {
  color: rgba(250, 250, 250, 0.75);
}
.inner-hr {
  margin-top: 20px!important;
  width: 10% !important;
  float: left;
  margin-right: 4%;
  border-color:#fd9700;
}
.inner-see-details {
  margin-bottom: 33px !important;
  font-size: 14px;
}
.pre-wrap {
  white-space: pre-wrap;
}
.homepage-product {
  margin-right: 35px !important;
}
.marginm300 {
  margin-top: -200px !important;
}
.cosmetics-row {
  margin-top: 21px;
}
.contact-text-center {
  margin-top: 40px;
  text-align: center;
  color: rgba(250, 250, 250, 0.75);

}
.about-page-texts {
  min-height: 95px !important; 
}
.button-length {
  width: 177px !important;
}
.homepage-products-button {
  width: 196px !important;
}



@media only screen and (max-width: 768px) {
  html{width: 100%; overflow-x: hidden;}
  body{width: 100%; overflow-x: hidden;}
  .cosmetics-see-details .home-hr {
    margin-top: 6px !important;
  }
  .submenu-items {
    .menu-names {
      margin-top: 34px;
    }
    .submenu-items{
      margin-left: 15px;
    }
  }

  .mobile-drawer {
    .close-icon img{padding:44px 30px 38px}
  }
  .MuiDrawer-paperAnchorLeft-5{width: 100%}
  div[class^='MuiDrawer-paperAnchorLeft'], div[class*=' MuiDrawer-paperAnchorLeft']{width: 100%}

  .solarium .title{line-height: 34px;}
  .home .title-and-description-position{margin-top: 100px;margin-bottom: 37px;}
  .home-hr{width: 40px;margin-right:15px;}


  .carousel {
    .slider-wrapper.axis-horizontal .slider .slide {display: flex;}
    .slider-wrapper.axis-horizontal .slider .slide > a:last-child {margin-top: 38px;}
    .slider-wrapper.axis-horizontal .slider .slide > a:last-child {display: none}
    .slider-wrapper.axis-horizontal .slider .slide.selected > a:last-child {display: block}
    button{background-image: none !important;}
    .carousel.carousel-slider{overflow: visible !important;height: 402px;}
    .slider-wrapper{max-height: 402px;}
    .slide{min-width: 90% !important;}
    .control-dots{padding: 0}
  }
  .four-image-content{margin-bottom: 60px;}

  .text{font-size: 13px !important;line-height: 21px !important;}

  .home .text-with-images .images-content{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .text-with-images__text a{display: block;text-align: center}
  .footer-img-logo{margin-top: 120px;}
  .solarium{margin-left: 9% !important;}

  .footer-mobile{margin-top: 70px;width: 88%;margin-left: 6%}
  .footer-mobile {
    .footer-last-part{ margin-top: 75px;}
    .contacts-icons{margin-bottom: 25px;}
    .footer-text {
      color: rgba(255, 255, 255, 0.87);
      font-size: 13px;
      text-transform: uppercase;
    }
    .contacts-icons hr{border-color: #fd9700 !important;width: 23%}
    .footer-last-part h3{font-size: 13px;line-height: 28px;margin: 0;}
    .copyright-mobile{margin-top:34px;}
    .copyright-mobile .home-hr{display: inline-block;position: absolute;left: 0;width: 60px;}
    .home-hr-title{width: 100%;text-align: center;position: relative;font-size: 13px;line-height: 16px;}
    .home-hr-title.footer-dot-mobile{display: none}
    .last-text{margin-top: 5px;margin-bottom: 52px}
  }
  .footer-menu-block{margin: 0 0 45px;}
  .footer-menu-block .menu-item{font-size: 13px;line-height: 28px;margin: 0;}


  .map {
    .carousel {
      .slider-wrapper{height: auto;background-color: transparent;}
      .slide{min-width: 91% !important}
      .control-dots {display: none}
    }
    .carousel.carousel-slider{height: auto;width: 100% !important;margin-left: -5%}
    .four-image-content {
      .four-image-item{padding: 20px 58px !important;}
      .four-image-item h3{font-size: 16px;line-height: 18px;}
      .four-image-item > div {font-size: 13px;white-space: initial;white-space: pre;}
    }
  }

  .header-content .text{line-height: 21px !important;  margin-bottom: 45px;}
  div[class^='MuiGrid-grid'], div[class*=' MuiGrid-grid']{
    max-width: 90% !important;
    flex-basis: 90% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  div[class^='MuiGrid-spacing'] , div[class*=' MuiGrid-spacing'] {
    margin: 0;
    padding: 0;
  }
  .four-title-and-description.small-title .home-hr{width: 47px;}
  .four-title-and-description .Collapsible > span,
  .four-title-and-description .Collapsible > span.is-open{font-size: 13px;font-weight: 400;line-height: 18px;}
  .Collapsible span.is-open{color: #fd9700;}
  .gift-card-context{margin-top: 145px;margin-bottom: 40px}
  .Collapsible__contentOuter .Collapsible__contentInner{padding: 0 0 30px;}
  .Collapsible .show-less-more{width: 100% !important;text-transform: inherit;text-align: right}
  .show-less-more div{float: right;display: inline-block;width: auto;font-size: 13px;margin-left: 18px}
  .show-less-more hr{display: inline-block;width: 40px;float: initial;}
  .solarium .Collapsible__contentInner .title{line-height: 24px;}
  .Collapsible span{font-weight: 400}
  .collapse-grid{margin-left: 0!important;}
  .solarium .menu-content-position{margin-left: 0;margin-right: 0;width: 100%}
  .header-content{margin-top: 65px}
  .tips-and-questions .tips-and-questions__button{margin-bottom: 90px !important;}
  .solarium-title-desc.four-title-and-description{padding-bottom: 40px;}
  .tips-and-questions .button-position{margin-top: 55px;}
  .tanning .tanning-title{line-height: 34px;font-size: 32px;margin-bottom: 20px !important;margin-top: 65px;}
  .four-image-content{margin-bottom: 0}
  .tanning .about-texts__items, .about .about-texts__items{font-size: 13px !important;line-height: 21px}
  .tanning .button-div{margin-bottom: 70px;margin-top: 24px;width: 100% !important;}
  .cosmetics-inner .header-part{width: 100%;}
  .cosmetics-inner .header-part .interested-questions{margin-right: 0px;margin-left: 0px !important;}
  .cosmetics-page-position{margin-top: 65px;width: 82%;margin-left: 9%;float: left}
  .interested-questions__question{font-size: 32px;line-height: 34px;margin-bottom: 20px;}
  .cosmetics-page-position .four-image-content{margin-top: 45px !important;}
  .video-and-text-content{width: 100%;margin:0}
  .button-div{text-align: center}
  .video-and-text-content .button-div{margin-top: 55px;margin-bottom: 70px}
  .video-and-text-content .see-details, .text-with-images .see-details, .four-title-and-description .see-details{padding: 15px 0 0;}
  .beauty-salon-page .four-image-content{margin-bottom: 125px}
  .contact-table tbody .table-row td{color: rgba(250, 250, 250, 1);}
  .contact-table tbody .table-row th{color: rgba(253, 151, 0, 1);}
  .contact-table thead th{border-color: rgba(253, 151, 0, 1);padding-left: 0px}
  .contact-table tbody .table-row th, .contact-table tbody .table-row td{border-color: rgba(253, 151, 0, 1);/*vertical-align: text-top;*/padding: 4px 20px 30px 0px;}
  .contact-table{margin-top: 0}
  .contact-table .contact-text-center{width: 90%;font-size: 20px}
  .contact-iframe{width: 82%;margin:50px 9% 70px}
  .Collapsible .coll-text.price_call{float: right;color:#DE8603;line-height: 28px;padding: 0 0 10px;font-size: 13px;}
  .Collapsible .coll-text.type_call{float: left;color:#DE8603;line-height: 28px;padding: 0 0 10px;font-size: 13px;}
  .Collapsible .coll-text{font-size: 13px;line-height: 28px}


  /*.contact-table table tbody{
    overflow-y: auto;
    height: 400px;
    width: 100%;
  }
  .contact-table table thead,.contact-table table tbody{
    display: block;
  }*/


  .about {

    .four-image-content{margin-bottom: 65px}
    .carousel .slider-wrapper {
      max-height: inherit
    }
    .interested-questions {width: 100% !important;max-height: inherit !important;}
    .interested-questions a{margin: 50px 0 0;display: block}
    .interested-questions__question{min-height: 24px !important;font-size: 24px;line-height: 24px; margin: 30px 0 40px;white-space: initial;text-align: left}
    .interested-questions__answer{font-size: 13px;line-height: 21px;white-space: initial;text-align: left}
    .about-texts{margin-bottom: 170px;width: 91% !important;}
  }

  .home .four-image-content{max-height: inherit;}
  .home .four-image-content .carousel .slider-wrapper{max-height: inherit;}
  .home .carousel .slider-wrapper.axis-horizontal .slider .slide{margin-bottom: 35px}
  .home .text-with-images{margin-top:35px !important;}
  .home .carousel .control-dots{display: none}
  .home .homepage-three-products .carousel .slider-wrapper.axis-horizontal .slider .slide{margin-bottom: 0}
  .solarium .four-image-content{margin-bottom: 30px;}
  .solarium.about .four-image-content{margin-bottom: 85px;}
  .solarium.beauty-salon-page .four-image-content{margin-bottom: 115px;}
  .solarium.beauty-salon-page .Collapsible__contentInner .four-image-content{margin-bottom: 25px}
  .solarium .solarium-title-desc.four-title-and-description, .gift-card .four-title-and-description{margin-top: 90px;}
  .solarium.tanning .video-and-text-content .hidden_questions .text{height: 170px !important;}
  .cosmetics-inner.cosmetics-page-position .four-image-content .carousel .slider-wrapper{overflow-y: visible;max-height: inherit}
  .cosmetics-inner.cosmetics-page-position .four-image-content .carousel.carousel-slider{overflow-y: visible;}
  .cosmetics-inner.cosmetics-page-position div[class^='MuiGrid-grid'],
  .cosmetics-inner.cosmetics-page-position div[class*=' MuiGrid-grid']{max-width: 100% !important;flex-basis: 100% !important;}
  .cosmetics-inner.cosmetics-page-position div[class*=' MuiPaper-elevation'],
  .cosmetics-inner.cosmetics-page-position div[class^='MuiPaper-elevation']{box-shadow: none}
  .cosmetics-inner.cosmetics-page-position .four-image-content{margin-bottom: 0px !important;}

  .cosmetics-inner .cosmetics-inner-header{margin-top: 150px;}
  .cosmetics-inner{margin-left: 9%;width: 82%;}
  .cosmetics-inner .four-image-content .control-next.control-arrow{display: none}
  .cosmetics-inner .four-image-content .control-prev.control-arrow{display: none}
  .cosmetics-inner .four-image-content .carousel.carousel-slider{overflow-y: visible;}
  .cosmetics-inner .four-image-content .carousel .slider-wrapper{overflow-y: visible;max-height: inherit}
  .cosmetics-inner .four-image-content{margin-bottom: 40px;}

  .mobile-drawer div.jss11 {width: 100%;}
  .solarium.home,.solarium.container{margin-left: 9% !important;width: 91%}
  .slider-wrapper.axis-horizontal .slider .slide {display: flex;opacity: 0.54}
  .slider-wrapper.axis-horizontal .slider .slide.selected {opacity: 1}

  /*.home .title-and-description-position,*/
  .center-title-and-description,
  .text-with-images__text,
  .text-with-images .text-with-images__text,
  .tanning .title,
  .about .title,
  .solarium .title,
  .solarium.container .four-title-and-description.small-title,
  .solarium.container .four-title-and-description.gift-card-context,
  .solarium.container .video-and-text-content,
  .tanning .tanning-title,
  .cosmetics-inner.cosmetics-page-position .header-part.position,
  .solarium.container.beauty-salon-page .Collapsible > span,
  .solarium.container.beauty-salon-page .position,
  .solarium.container .menu-content-position .position,
  .solarium.container .menu-content-position .Collapsible > span,
  .solarium.container .menu-content-position .Collapsible .coll-text,
  .solarium.container .menu-content-position .Collapsible .show-less-more,
  .solarium.container .tips-and-questions,
  .solarium.container.beauty-salon-page .Collapsible .cost.coll-text,
  .cosmetics-inner,
  .cosmetics-inner .header-part,
  .cosmetics-inner .carousel .slider-wrapper.axis-horizontal .slider .slide.selected .carousel{width: 90% !important;}
  .cosmetics-inner.cosmetics-page-position .carousel .slide{margin-right: 9% !important;min-width: 90% !important;}
  .solarium.container .menu-content-position{width: 100% !important;}
  .cosmetics-inner,
  .cosmetics-inner.cosmetics-page-position{width: 91% !important;}

  .cosmetics-inner.cosmetics-page-position .carousel .slider-wrapper.axis-horizontal .slider .slide.selected .carousel{width: 100% !important;}

  .solarium.container .video-and-text-content{margin-left: 0 !important;margin-right: 0 !important;}

  .about .carousel .slider-wrapper.axis-horizontal .slider .slide .interested-questions{display: none}
  .about .carousel .slider-wrapper.axis-horizontal .slider .slide.selected .interested-questions{display: block}
  .cosmetics-inner .carousel .slider-wrapper.axis-horizontal .slider .slide .carousel,
  .cosmetics-inner .carousel .slider-wrapper.axis-horizontal .slider .slide .position.cosmetics-see-details{display: none}
  .cosmetics-inner .carousel .slider-wrapper.axis-horizontal .slider .slide.selected .carousel,
  .cosmetics-inner .carousel .slider-wrapper.axis-horizontal .slider .slide.selected .position.cosmetics-see-details{display: block}

  .solarium.container.beauty-salon-page .Collapsible .coll-text.price_call,
  .solarium.container .menu-content-position .Collapsible .show-less-more{margin-right: 9%!important;}
  .solarium.container.beauty-salon-page .Collapsible .show-less-more{margin-right: 9%!important;}
  .solarium.container .solarium-title-desc.four-title-and-description{margin-top: 0}

  /*.about .about-texts div {
    width: initial;
    max-width: initial !important;;
    flex-basis: initial !important;
  }*/

  /*.contact-table tbody .table-row, .contact-table thead .table-row{position: relative}
  .contact-table thead .table-row th:first-child,
  .contact-table tbody .table-row th:first-child{
    position: absolute;width: 100px;top: 30px;
  }
  .contact-table thead .table-row th:nth-child(2),
  .contact-table tbody .table-row th:nth-child(2){padding-left: 100px;}*/

  .header{position: fixed;width: 100% !important;height: 90px;z-index: 10;background: #1b1b1a}
  .home .title-and-description-position{margin-top: 180px;}
  .header .mobile-header-button{margin-right: 30px;}
  .header-content{margin-top: 145px;}
  .tanning .tanning-title,
  .cosmetics-page-position{margin-top: 145px;}
  .contact-table{margin-top: 79px;}
  .mobile-drawer div{width: 100%;z-index: 100000}

  div[class^='jss'], div[class*=' jss']{
    width: 90% !important;
    max-width: 90% !important;
    flex-basis: 90% !important;
    margin-left: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    background-color: transparent;
    border-radius: 0px;
    box-shadow: none;
  }
  div[class^='jss'].mobile-drawer, div[class*=' jss'].mobile-drawer{
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-left: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    background-color: transparent;
    border-radius: 0px;
    box-shadow: none;
    z-index: 10000;
  }
  div[class^='jss'].mobile-drawer div[class^='jss'], div[class*=' jss'].mobile-drawer div[class^='jss']{
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-left: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    background-color: transparent;
    border-radius: 0px;
    box-shadow: none;
  }
  div[class^='jss'] div, div[class*=' jss'] div{width: auto !important;max-width: none!important;flex-basis: auto!important}
  .about-texts{width: 90% !important;}
  .about-texts__items{width: 100% !important;}

  .carousel .slide img {
    float: left;
    max-width: inherit;
  }

  /*.cosmetics-inner{padding-top: 85px;}*/
  .gm-style img{display: block !important;}
  .gm-control-active>img{left: 20px;}
  button.gm-control-active{width: 40px !important;height: 40px !important;}
  .contact-table-out{overflow-x:auto;}
  .header-mobile-social .contacts-icons{width: 100% !important;}

  .contact-table div[class*=" jss"], .contact-table div[class^=jss]{
    width: 100%!important;
    max-width: 100%!important;
    flex-basis: 100%!important;
    margin-left: 0!important;
    padding: 0!important;
    overflow-x: auto !important;
    background-color: transparent;
    border-radius: 0;
    margin-top: 50px;
    box-shadow: none;}
}
.mobile-map-slider{
  // white-space: pre;
  // position: relative;
  //   min-height: 1px;
  //   padding-right: 15px;
  //   padding-left: 15px;
  //   white-space: pre;
}



$white:    #fff !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;

$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;

$carousel-control-icon-width:        20px !default;

$carousel-control-prev-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23") !default;
$carousel-control-next-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$enable-gradients:                            false !default;


@mixin hover {
  &:hover { @content; }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}


@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

// stylelint-disable property-blacklist
@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  @if $enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}



//bootsrap carousel

// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel-item-left and .carousel-item-right is used to indicate where
//    the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-left and .active.carousel-item-right is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-left and .carousel-item-prev.carousel-item-right
//    is the upcoming slide in transition.

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include clearfix();
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  @include transition($carousel-transition);
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}


//
// Alternate transitions
//

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    @include transition(0s $carousel-transition-duration opacity);
  }
}


//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  @include transition($carousel-control-transition);

  // Hover/focus state
  @include hover-focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: $carousel-control-hover-opacity;
  }
}
.carousel-control-prev {
  left: 0;
  @if $enable-gradients {
    background: linear-gradient(90deg, rgba($black, .25), rgba($black, .001));
  }
}
.carousel-control-next {
  right: 0;
  @if $enable-gradients {
    background: linear-gradient(270deg, rgba($black, .25), rgba($black, .001));
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: no-repeat 50% / 100% 100%;
}
.carousel-control-prev-icon {
  background-image: $carousel-control-prev-icon-bg;
}
.carousel-control-next-icon {
  background-image: $carousel-control-next-icon-bg;
}


// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: $carousel-indicator-active-bg;
    background-clip: padding-box;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    border-top: $carousel-indicator-hit-area-height solid transparent;
    border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: .5;
    @include transition($carousel-indicator-transition);
  }

  .active {
    opacity: 1;
  }
  .aaaa{}
}


// Optional captions
//
//

.carousel-caption {
  position: absolute;
  right: (100% - $carousel-caption-width) / 2;
  bottom: 20px;
  left: (100% - $carousel-caption-width) / 2;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
}

@media only screen and (max-width: 768px){
  /*.map .four-image-content{top:60px;}*/
  .gm-style a img{display: none !important;}
  .map .four-image-content .carousel-inner .carousel-item h3{
    font-size: 16px;
    line-height: 18px;
  }
  .map .four-image-content{margin-left: -45% !important;}
  .map .four-image-content .carousel-inner {margin-left: -5%;}
  .map .four-image-content .carousel-inner .carousel-item{
    min-width: 100%!important;
    width: 260px !important;
    height: 258px!important;
  }
  .map .four-image-content .carousel-inner .carousel-item > div{
    font-size: 13px;
    line-height: 16px;
    background-color: #1b1b1a!important;
    padding: 20px 40px!important;
    width: 90%!important;
    margin-left: 5%;margin-right: 5%;
  }
  .map .four-image-content .carousel-control-prev,
  .map .four-image-content .carousel-control-next{
    display: none;
  }



  div[role=dialog] div:nth-child(2) > div{
    background-color: #fff !important;
    width: 100% !important;
    max-width: 960px !important;
    flex: 0 1 auto !important;
    max-height: calc(100% - 96px) !important;
    margin: 30px!important;
    display: flex !important;
    position: relative !important;
    overflow-y: auto !important;
    flex-direction: column !important;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
    border-radius: 4px !important;
    overflow-x: visible;
  }

  div[role=dialog].jss13{width: 100% !important;max-width: 100%!important;}
  div[role=dialog] div:nth-child(2) > div h2 div{
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    top: 8px;
    color: #9e9e9e;
    right: 8px;
    position: absolute;
    flex: 0 0 auto;
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    margin-right: -25px !important;;
    margin-top: -25px !important;
  }

  div[role=dialog] div:nth-child(2) > div h2 div button span:first-child{text-align: right;display: block;}
  div[role=dialog] div:nth-child(2) > div h2 div img{
    width: 20px; height: 20px;
  }
  #responsive-dialog-title{padding: 24px 24px 20px !important;}

}
.submenu-items a.cred{
  color: #fd9700 !important;
}


